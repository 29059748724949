const FONTS = {
  FONTSMALL: '1.4rem',
  FONTMEDIUM: '1.6rem',
  FONTBASE: '2rem',
  FONTLARGE: '2.2rem',
  FONTXLARGE: '2.4rem',
  FONTBUTTON: '2.5rem',
  FONTINPUTS: '2.2rem',
  FONTLABEL: '1.8rem',
  FONTMENU: '3.6rem',
  FONTSUBMENU: '1.6rem',
  FONTSUBMENULARGE: '2.1rem',
  FONTTITLE: '5rem',
  FONTTITLELARGE: '4.2rem',
  FONTSUBTITLE: '3rem',
  FONTFORMTITLE: '2.8rem',
  WEIGHTLIGHT: '300',
  WEIGHTREGULAR: '400',
  WEIGHTMEDIUM: '500',
  WEIGHTSEMI: '600',
  WEIGHTBOLD: '700',
  TEXTINTRO: '2.4rem',
  TEXTCOLUMNS: '2.2rem',
  TEXTSMALL: '1.8rem',
  TEXTDEFAULT: '2rem',
  TEXTMEDIUM: '3.2rem',
  TEXTLARGE: '3.6rem',
  TITLEONE: '5rem',
  TITLETWO: '4rem',
  TITLETHREE: '3.6rem',
  TITLEFOUR: '2.6rem',
}

export default FONTS
