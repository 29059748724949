import React from 'react'
import {
  Link, navigate,
} from 'gatsby'
import styled from 'styled-components'
import FONTS from '../../constants/fonts'
import media from '../../constants/media'
import {
  Logo,
} from '../StyledComponents'
import GoogleBadge from '../Blocks/GoogleBadge/GoogleBadge'

const TopBarWrap = styled.div`
  width: 100%;
  display: flex;
`
const TopBarContent = styled.div`
  width: 100%;
  position: relative;
  z-index: 3;
  ${Logo} {
    margin: auto;
  }
  button {
    position: absolute;
    left: -15px;
    top: 15px;
  }
  ${media.small`
  button {
    left: 0;
  }
  `}
  ${media.medium`
    button {
      top: 50%;
      margin-top: -15px;
    }
  `}
`
const TopBarRight = styled.div`
  text-align: center;
  margin-top: 20px;
  ${media.medium`
    position: absolute;
    right: 0;
    top: 50%;
    margin-top: -20px;
  `}
`
const MenuWrap = styled.div`
  background: rgba(255, 255, 255, 1);
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 2;
`
const MenuInner = styled.div`
  max-width: 1040px;
  padding: 160px 20px 0 20px;
  margin: auto;
  text-align: center;
  button {
    margin-top: 24px;
  }
  ${media.small`
    padding: 200px 20px 0 20px;
  `}
`
const MainMenu = styled.div`
  a {
    color: #333399;
    display: block;
    font-size: ${FONTS.FONTMENU};
    font-weight: ${FONTS.WEIGHTMEDIUM};
    margin-bottom: 1.5rem;
    text-decoration: none;
  }
`
const MainMenuContent = styled.div`
  clear: both;
  padding: 0 40px;
`
const MODAL_OPEN_CLASS = 'body--modal-open'
class TopBar extends React.Component {
  constructor() {
    super()

    this.state = {
      showMenu: false,
    }
    this.showMenu = this.showMenu.bind(this)
    this.closeMenu = this.closeMenu.bind(this)
  }

  showMenu(event) {
    event.preventDefault()
    document.body.classList.add(MODAL_OPEN_CLASS)
    this.setState(
      {
        showMenu: true,
      }
    )
  }

  closeMenu(event) {
    event.preventDefault()
    document.body.classList.remove(MODAL_OPEN_CLASS)
    this.setState(
      {
        showMenu: false,
      }
    )
  }

  homeClick = () => {
    navigate('/')
  }

  removeOverflowClass = () => {
    document.body.classList.remove(MODAL_OPEN_CLASS)
  }

  render() {
    return (
      <TopBarWrap>
        <TopBarContent>
          <button
            type='button'
            onClick={this.state.showMenu ? this.closeMenu : this.showMenu}
            className={this.state.showMenu ? 'close-x' : 'burger-menu'}
            aria-label='Main navigation toggle'
          />
          <Logo small={!this.props.isTop} onClick={this.homeClick} />
          {this.state.showMenu ? (
            <TopBarRight>
              <a target='_blank' rel='noopener noreferrer' href='https://play.google.com/store/apps/details?id=com.chartmedic.interviews.app'>
                <GoogleBadge />
              </a>
            </TopBarRight>
          ) : null}
        </TopBarContent>
        {this.state.showMenu ? (
          <MenuWrap>
            <MenuInner>
              <MainMenu>
                <MainMenuContent>
                  <Link onClick={this.removeOverflowClass} to='/'>Home</Link>
                  <Link onClick={this.removeOverflowClass} to='/how-it-works/'>How it Works</Link>
                  <Link onClick={this.removeOverflowClass} to='/benefits/'>Benefits</Link>
                  <Link onClick={this.removeOverflowClass} to='/get-started/'>Get Started</Link>
                  <Link onClick={this.removeOverflowClass} to='/pilot/'>Pilot Program</Link>
                  <Link onClick={this.removeOverflowClass} to='https://chartmedic.com/blog/'>Blog</Link>
                  <Link onClick={this.removeOverflowClass} to='/about/'>About Us</Link>
                  <Link onClick={this.removeOverflowClass} to='/contact/'>Contact Us</Link>
                </MainMenuContent>
              </MainMenu>
            </MenuInner>
          </MenuWrap>
        ) : null}
      </TopBarWrap>
    )
  }
}

export default TopBar
