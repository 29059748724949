const COLORS = {
  WHITE: '#FFFFFF',
  BLACK: '#000000',
  BLUE: '#003da6',
  BLUETRANS: 'rgba(0,61,166,.75)',
  TURQ: '#009999',
  GREYLIGHT: '#f6f6f6',
  GREYMEDIUM: '#cccccc',
  GREYDARK: '#666666',
  BORDERS: '#cccccc',
}

export default COLORS
