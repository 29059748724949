import React from 'react'
import styled from 'styled-components'

import LogoImg from '../../../assets/images/download-app/google_play.svg'

const Badge = styled.span`
  background-color: transparent;
  background-image: url(${LogoImg});
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  height: 40px;
  width: 136px;
  display: inline-block;
`

const GoogleBadge = () => <Badge />

export default GoogleBadge
