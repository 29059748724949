import React from 'react'
import classnames from 'classnames'
import TopBar from '../TopBar'
import {
  PageHeader, Content,
} from '../StyledComponents'

class Header extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      // prevScrollpos: window.pageYOffset,
      prevScrollpos: 0.0,
      visible: true,
      isTop: true,
    }
  }

  // Adds an event listener when the component is mount.
  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll)
  }

  // Remove the event listener when the component is unmounted.
  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  }

  // Hide or show the menu.
  handleScroll = () => {
    const {
      prevScrollpos,
    } = this.state
    const isTop = window.pageYOffset < 40
    if (isTop !== this.state.isTop) {
      this.setState({
        isTop,
      })
    }
    const currentScrollPos = window.pageYOffset
    const visible = prevScrollpos > currentScrollPos

    this.setState({
      prevScrollpos: currentScrollPos,
      visible,
    })
  }

  render() {
    return (
      <div
        className={classnames('navbar', {
          'navbar--hidden': !this.state.visible && !this.state.isTop,
          'navbar--scrolling': !this.state.isTop,
        })}
      >
        <PageHeader scrolling={(!this.state.isTop).toString()}>
          <Content>
            <TopBar isTop={this.state.isTop} />
          </Content>
        </PageHeader>
      </div>
    )
  }
}

export default Header
