import React from 'react'
import { Link } from 'gatsby'
import './styles.css'

export default () => (
  <div id="pilot-cta" className='bottom-apply'>
    <h6>
      Join our Pilot Program to try ChartMedic for free!
      <Link to='/pilot'>Apply Now </Link>     
      to learn how ChartMedic can improve your healthcare organization's intake experience.
    </h6>
  </div>
)
