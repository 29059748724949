import styled from 'styled-components'
import Link from 'gatsby-link'
import media from '../../constants/media'
import colors from '../../constants/colors'
import fonts from '../../constants/fonts'
import logo from '../../assets/images/logo-chartmatic.svg'
import IconAccuracy from '../../assets/images/icons-steps/icon-Accuracy.svg'
import IconMobileApp from '../../assets/images/icons-steps/icon-MobileApp.svg'
import IconCharting from '../../assets/images/icons-steps/icon-Charting.svg'
import IconEpicComputer from '../../assets/images/icons-steps/icon-EpicComputer.svg'
import IconSecure from '../../assets/images/icons-steps/icon-Secure.svg'
import IconEasy from '../../assets/images/icons-steps/icon-Easy.svg'
import IconLanguages from '../../assets/images/icons-steps/icon-Languages.svg'
import IconTimeMoney from '../../assets/images/icons-steps/icon-TimeMoney.svg'
import IconPeople from '../../assets/images/icons-steps/icon-People.svg'
import IconFlexible from '../../assets/images/icons-steps/icon-Flexible.svg'
import IconInvoice from '../../assets/images/icons-steps/icon-Invoice.svg'
import IconNetwork from '../../assets/images/icons-steps/icon-Network.svg'
import IconVacation from '../../assets/images/icons-steps/icon-Vacation.svg'
import IconWorking from '../../assets/images/icons-steps/icon-Working.svg'
import IconCheck from '../../assets/images/icons-custom/icon-Check.svg'
import IconTarget from '../../assets/images/icons-custom/icon-Target.svg'
import IconTime from '../../assets/images/icons-custom/icon-Time.svg'
import IconQuality from '../../assets/images/icons-custom/icon-Quality.svg'
import IconCheckWhite from '../../assets/images/icons-custom/icon-CheckWhite.svg'
import IconQualityWhite from '../../assets/images/icons-custom/icon-QualityWhite.svg'
import IconTimeWhite from '../../assets/images/icons-custom/icon-TimeWhite.svg'
import IconTargetWhite from '../../assets/images/icons-custom/icon-TargetWhite.svg'
import SelectArrow from '../../assets/images/select-arrow.png'

export const HomePage = styled.div`
  position: static;
`
export const Title = styled.h1`;
  font-size: ${fonts.FONTTITLE};
  font-weight: ${fonts.WEIGHTLIGHT};
  line-height: 43px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0;
  margin-bottom: ${(props) => (props.margin ? '30px' : '0')};
  max-width: ${(props) => (props.short ? '600px' : '1040px')};
`
export const SubTitle = styled.h2`
  color: ${colors.BLUE};
  font-size: ${fonts.FONTTITLELARGE};
  font-weight: ${fonts.WEIGHTREGULAR};
  margin-top: 0;
  margin-bottom: ${(props) => (props.margin ? '30px' : '10px')};
  text-align: ${(props) => (props.alignLeft ? 'left' : 'center')};
`
export const Page = styled.div`
  position: static;
  padding-top: ${(props) => (props.noHero ? '240px' : '0')};
`
export const Logo = styled.div`
  width: ${(props) => (props.small ? '220px' : '220px')};
  height: ${(props) => (props.small ? '60px' : '60px')};
  background-image: url(${logo});
  background-size: cover;
  cursor: pointer;
  transition: all 0.5s ease;
  z-index: 3;
  position: relative;
  ${media.small`
  width: ${(props) => (props.small ? '220px' : '320px')};
  height: ${(props) => (props.small ? '60px' : '86px')};
   `}
`
export const Content = styled.div`
  margin-right: auto;
  margin-left: auto;
  margin-top: ${(props) => (props.margined ? '60px' : '0')};
  margin-bottom: ${(props) => (props.margined ? '60px' : '0')};
  padding-top: ${(props) => (props.padded ? '60px' : '0')};
  padding-bottom: ${(props) => (props.padded ? '60px' : '0')};
  padding-left: 20px;
  padding-right: 20px;
  max-width: ${(props) => (props.short ? '640px' : '1040px')};
  text-align: ${(props) => (props.center ? 'center' : 'left')};
`
export const FlexRow = styled(Content)`
  display: flex;
`
export const BorderTopWrap = styled.div`
  border-top: 2px solid ${colors.BORDERS};
`
export const ColorWrap = styled.div`
  color: ${(props) => (props.fontWhite ? '#ffffff' : '#000000')};
  overflow: auto;
  background-color: ${({ Color }) =>
    (Color === 'gray' && colors.GREYLIGHT) ||
    (Color === 'blue' && colors.BLUE) ||
    (Color === 'turq' && colors.TURQ) ||
    '#FFFFFF'};
  margin-top: ${(props) => (props.pull ? '-60px' : '0')};
  a:link,
  a:visited,
  a:hover {
    color: ${colors.WHITE};
  }
`
export const PageHeader = styled.header`
  padding-top: ${(props) => (props.scrolling ? '0' : '35px')};
  width: 100%;
  z-index: 2;
  text-align: center;
  ${Content} {
    display: flex;
  }
  transition: all 0.5s ease;
`
export const Main = styled.main`
  padding-top: ${(props) => (props.noHero ? '240px' : '0')};
`
export const Paragraph = styled.p`
  font-size: ${fonts.FONTBASE};
  line-height: 24px;
  margin-bottom: 20px;
`
export const IntroParagraph = styled(Paragraph)`
  font-size: ${fonts.FONTXLARGE};
  font-weight: ${fonts.WEIGHTLIGHT};
  line-height: 30px;
  margin: ${(props) => (props.noMargin ? '0 0' : '20px 0')};
`
export const ButtonLink = styled(Link)`
  background: ${colors.BLUE};
  border-radius: 4px;
  color: ${colors.WHITE};
  display: inline-block;
  font-size: ${fonts.FONTBUTTON};
  margin: 5px;
  padding: 20px 100px;
  text-decoration: none;
  &:link,
  &:visited,
  &:hover {
    color: ${colors.WHITE};
    text-decoration: none;
  }
  ${media.xxsmall`
  padding: 10px 40px;
 `}
    ${media.small`
    padding: 20px 100px;
    `}
  }
`
export const SectionIcon = styled.span`
  display: inline-block;
  height: ${({ Size }) =>
    (Size === 'sizeSmall' && '180px') ||
    (Size === 'sizeMedium' && '225px') ||
    '250px'};
  width: ${({ Size }) =>
    (Size === 'sizeSmall' && '180px') ||
    (Size === 'sizeMedium' && '225px') ||
    '250px'};
  border: 2px solid ${colors.BORDERS};
  border-radius: 50%;
  background-color: ${colors.WHITE};
  background-repeat: no-repeat;
  background-position: center center;
  background-size: auto 140px;
  background-size: ${({ Size }) =>
    (Size === 'sizeSmall' && 'auto 100px') ||
    (Size === 'sizeMedium' && 'auto 120px') ||
    'auto 140px'};
`
export const SectionIconAccuracy = styled(SectionIcon)`
  background-image: url(${IconAccuracy});
`
export const SectionIconMobileApp = styled(SectionIcon)`
  background-image: url(${IconMobileApp});
`
export const SectionIconCharting = styled(SectionIcon)`
  background-image: url(${IconCharting});
`
export const SectionIconEpicComputer = styled(SectionIcon)`
  background-image: url(${IconEpicComputer});
`
export const SectionIconSecure = styled(SectionIcon)`
  background-image: url(${IconSecure});
`
export const SectionIconEasy = styled(SectionIcon)`
  background-image: url(${IconEasy});
`
export const SectionIconLanguages = styled(SectionIcon)`
  background-image: url(${IconLanguages});
`
export const SectionIconTimeMoney = styled(SectionIcon)`
  background-image: url(${IconTimeMoney});
  background-size: ${({ Size }) =>
    (Size === 'sizeSmall' && '110px auto') ||
    (Size === 'sizeMedium' && '120px auto') ||
    '140px auto'}
`
export const SectionIconPeople = styled(SectionIcon)`
  background-image: url(${IconPeople})
  background-size: ${({ Size }) =>
    (Size === 'sizeSmall' && '120px auto') ||
    (Size === 'sizeMedium' && '130px auto') ||
    '140px auto'};
`
export const SectionIconFlexible = styled(SectionIcon)`
  background-image: url(${IconFlexible});
  background-size: ${({ Size }) =>
    (Size === 'sizeSmall' && '120px auto') ||
    (Size === 'sizeMedium' && '140px auto') ||
    '160px auto'};
`
export const SectionIconInvoice = styled(SectionIcon)`
  background-image: url(${IconInvoice});
  background-size: ${({ Size }) =>
    (Size === 'sizeSmall' && '100px auto') ||
    (Size === 'sizeMedium' && '115px auto') ||
    '130px auto'};
`
export const SectionIconNetwork = styled(SectionIcon)`
  background-image: url(${IconNetwork})
  background-size: ${({ Size }) =>
    (Size === 'sizeSmall' && '110px auto') ||
    (Size === 'sizeMedium' && '130px auto') ||
    '145px auto'}
`
export const SectionIconVacation = styled(SectionIcon)`
  background-image: url(${IconVacation});
  background-size: ${({ Size }) =>
    (Size === 'sizeSmall' && '100px auto') ||
    (Size === 'sizeMedium' && '120px auto') ||
    '135px auto'};
`
export const SectionIconWorking = styled(SectionIcon)`
  background-image: url(${IconWorking});
  background-size: ${({ Size }) =>
    (Size === 'sizeSmall' && '120px auto') ||
    (Size === 'sizeMedium' && '140px auto') ||
    '160px auto'};
`
export const SectionIconRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 10px;
  ${SectionIcon} {
    margin: 0 20px 20px;
  }
`
export const CustomIcon = styled.span`
  display: block;
  height: 130px;
  width: 130px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: auto 125px;
`
export const CustomIconCheck = styled(CustomIcon)`
  background-image: url(${IconCheck});
`
export const CustomIconTarget = styled(CustomIcon)`
  background-image: url(${IconTarget});
`
export const CustomIconQuality = styled(CustomIcon)`
  background-image: url(${IconQuality});
`
export const CustomIconTime = styled(CustomIcon)`
  background-image: url(${IconTime});
`
export const CustomIconWhite = styled.span`
  display: block;
  height: 155px;
  width: 155px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: auto 155px;
`
export const CustomIconCheckWhite = styled(CustomIconWhite)`
  background-image: url(${IconCheckWhite});
`
export const CustomIconTargetWhite = styled(CustomIconWhite)`
  background-image: url(${IconTargetWhite});
`
export const CustomIconTimeWhite = styled(CustomIconWhite)`
  background-image: url(${IconTimeWhite});
`
export const CustomIconQualityWhite = styled(CustomIconWhite)`
  background-image: url(${IconQualityWhite});
`
export const TeamMembers = styled.div`
  background: ${colors.GREYLIGHT};
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`
export const TeamMember = styled.div`
  max-width: 333px;
  width: 100%;
  margin-top: 50px;
  display: flex;
  flex-direction: column;
`
export const TeamMemberContent = styled.div`
  background: ${colors.WHITE};
  padding: 20px 20px 50px;
  margin: 5px;
  flex: 1;
`
export const TeamMemberTitle = styled.h3`
  font-size: ${fonts.FONTXLARGE};
`
export const TeamMemberDesc = styled.div`
  font-size: ${fonts.FONTBASE};
`
export const TeamMemberImg = styled.div`
  img {
    margin-left: auto;
    margin-right: auto;
    display: block;
  }
`
export const PricingTable = styled.table`
  margin-top: 40px;
  border-collapse: collapse;
  width: 100%;
  text-align: left;
  th,
  td {
    padding: 15px 20px;
  }
  th {
    background-color: ${colors.WHITE};
    font-size: 20px;
    font-weight: ${fonts.WEIGHTMEDIUM};
  }
  tr:nth-child(odd) {
    background-color: #ced0d0;
  }
  td {
    font-size: 17px;
    font-weight: ${fonts.WEIGHTMEDIUM};
    span {
      display: block;
      font-size: 15px;
      font-weight: ${fonts.WEIGHTLIGHT};
    }
  }
  ${media.medium`
  td {
    span {
      display: inline-block;
    }
  }
   `}
`
export const FormWrap = styled.div`
  max-width: 780px;
  background: ${colors.WHITE};
  margin: auto;
  padding: 25px 30px 30px 30px;
  label {
    font-size: ${fonts.FONTLABEL};
  }
  input,
  textarea {
    border: 0;
    display: block;
    width: 100%;
    padding: 8px 8px 8px 0;
    border-bottom: 1px solid ${colors.BORDERS};
    &:focus {
      outline: none;
    }
  }
  select {
    border: 0;
    display: block;
    width: 100%;
    padding: 8px;
    border-bottom: 1px solid ${colors.BORDERS};
    background-color: ${colors.WHITE};
    background-image: url(${SelectArrow});
    background-size: 16px 15px;
    background-position: right 10px center;
    background-repeat: no-repeat;
    border-radius: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    height: 44px;
    &:focus {
      outline: none;
      background-color: ${colors.WHITE};
      border-bottom: 1px solid ${colors.BORDERS};
    }
    &:invalid {
      color: ${colors.GREYMEDIUM};
    }
  }
  button,
  input[type='submit'] {
    background: ${colors.BLUE};
    border-radius: 4px;
    color: ${colors.WHITE};
    cursor: pointer;
    display: inline-block;
    font-size: ${fonts.FONTBUTTON};
    margin: 5px;
    padding: 20px 35px;
    text-decoration: none;
    display: inline-block;
    width: auto;
  }
`
export const FormTitle = styled.div`
  color: ${colors.BLUE};
  font-size: ${fonts.FONTFORMTITLE};
`
export const FormRow = styled.div`
  margin: 2rem 0;
  display: flex;
  flex-direction: column;
  label {
    width: 100%;
  }
  ${media.medium`
  flex-direction: row;
  `}
  #label-referredByDescription {
    display: none;
  }
`
export const FormRowSplit = styled(FormRow)`
  label {
    margin-bottom: 2rem;
  }
  ${media.medium`
    flex-wrap: wrap;
    justify-content: space-between;
    label {
      width: calc(50% - .5rem);
      padding-right: 1rem;
    }
  `}
`

export const FormButtonRow = styled.div`
  margin-top: 25px;
  ${media.medium`
    display: flex;
    justify-content: center;
  `}
`

export const FormRecapchaRow = styled.div`
  margin: 25px auto;
  text-align: center;
  canvas {
    border: 1px solid #ccc;
    width: 15rem;
  }
  #retryButton {
    padding: 0;
    color: #fff;
    height: 4rem;
    width: 4rem;
    background-color: #ccc;
    margin-left: 1rem;
  }
`

export const Oops = styled.div`
  color: ${colors.BLUE};
  font-size: 3.6rem;
  font-weight: 500;
  margin: 4rem 0 0.25rem 0;
`

export const OopsText = styled.div`
  font-size: 2.5rem;
  margin: 4rem 0 8rem 0;
`

export const Underline = styled.span`
  text-decoration: underline;
`

export const SectionHeader = styled.h1`
  font-size: 20px;
  font-weight: 600;
`

export const FormCheckboxWrapper = styled.div`
 display: flex;
 gap: 0 8px;
 margin: 2rem 0;
 input {
   transform: scale(1.2);
   width: unset;
 }
 label {
  margin-top: -5px;
 }
`
