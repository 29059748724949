import React from 'react'
import styled from 'styled-components'

import {
  Link,
} from 'gatsby'
import colors from '../../constants/colors'
import media from '../../constants/media'

import GoogleBadge from '../Blocks/GoogleBadge/GoogleBadge'
import PilotCta from '../Blocks/PilotCta'

const FooterWrap = styled.footer`
  color: ${colors.WHITE};
  padding: 20px;
  background: ${colors.BLUE};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  a {
    color: ${colors.WHITE};
  }
  a:visited {
    color: ${colors.WHITE};
  }
  ${media.medium`
    flex-direction: row;
  `}
`
const Legal = styled.span`
  margin: 10px 0;
  display: inline-block;
`

export const Disclaimer = styled.div`
  font-size: 1.1rem;
  ${media.xxsmall`
    padding-bottom: 20px;
    a {
      color: ${colors.WHITE};
    }
    a:visited {
      color: ${colors.WHITE};
    }
  `}
`
const Footer = ({ hiddenPilotCta }) => (
  <>
    {
      !hiddenPilotCta &&
        <PilotCta />
    }
    <FooterWrap id='site-footer'>
      <div>
        <Legal>
          ©{new Date().getFullYear()} ChartMedic |{' '}
          <Link to='/privacy-terms'>Privacy</Link> |{' '}
          <Link to='/privacy-terms#Terms'>Terms of Use</Link>
        </Legal>
        <Disclaimer>Epic and the Epic logo <i>are trademarks of Epic Systems Corporation</i></Disclaimer>
      </div>
      <a target='_blank' rel='noopener noreferrer' href='https://play.google.com/store/apps/details?id=com.chartmedic.interviews.app'>
        <GoogleBadge />
      </a>
    </FooterWrap>
  </>
)

export default Footer
