import {
  css,
} from 'styled-components'

const sizes = {
  xlarge: 1400,
  large: 1280,
  medium: 1000,
  small: 800,
  xsmall: 640,
  xxsmall: 0,
}
export default Object.keys(sizes).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (min-width: ${sizes[label]}px) {
      ${css(...args)};
    }
  `
  return acc
}, {})
